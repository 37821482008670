/* eslint-disable react/prop-types */
import React from 'react';
import getCDNImage from '../../utils/getCDNImage';

import styles from './backgroundImage.module.scss';

const BackgroundImage = ({ src }) => (
  <div data-testid="background-image" className={styles.backgroundImage}>
    <picture>
      <source
        media="(min-width: 1560px)"
        srcSet={`https://res.cloudinary.com/crop/image/fetch/f_auto,q_70,c_scale,fl_lossy,h_1200/${getCDNImage(src)}`}
      />
      <source
        media="(min-width: 1200px)"
        srcSet={`https://res.cloudinary.com/crop/image/fetch/f_auto,q_70,c_scale,fl_lossy,h_900/${getCDNImage(src)}`}
      />
      <source
        media="(min-width: 984px)"
        srcSet={`https://res.cloudinary.com/crop/image/fetch/f_auto,q_70,c_scale,fl_lossy,h_1200/${getCDNImage(src)}`}
      />
      <source
        media="(min-width: 768px)"
        srcSet={`https://res.cloudinary.com/crop/image/fetch/f_auto,q_70,c_scale,fl_lossy,h_1024/${getCDNImage(src)}`}
      />
      <img
        src={`https://res.cloudinary.com/crop/image/fetch/f_auto,q_70,c_scale,fl_lossy,h_480/${getCDNImage(src)}`}
        alt="background illustration"
        loading="eager"
      />
    </picture>
  </div>
);

export default BackgroundImage;
